<template>
    <!-- 全局配置 -->
    <el-card
            shadow="hover"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="height: 100%;overflow-y: auto;"
    >
        <div v-loading="loading" style="height: 100%;">
            <el-card>
                <el-row justify="end" type="flex">
                    <el-col>
                        <h3>全局配置</h3>
                    </el-col>
                    <el-col :span="3" style="margin:15px 0">
                        <el-button type="primary" class="button" @click="addlis()" icon="el-icon-circle-plus-outline" round>新建全局配置</el-button>
                    </el-col>
                </el-row>
                <!-- 表格列表 -->
                <el-row>
                    <el-table ref="singleTable" :data="tableData" border style="width: 100%" height="630px">
                        <el-table-column property="key" label="KEY" align="center"></el-table-column>
                        <el-table-column property="value" label="VALUE" align="center"></el-table-column>

                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" @click="replace(scope.$index, scope.row)">更新</el-button>
                                <el-button size="mini" type="warning" @click="edit(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="del(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <!-- 分页器 -->
                <el-row>
                    <div class="page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="queryInfo.page"
                                :page-size="queryInfo.pagenum"
                                :page-sizes="[20, 50, 100, 200,500]"
                                style="float:right"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="total"
                        ></el-pagination>
                        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
                    </div>
                </el-row>
                <!-- 新增全局配置 -->
                <el-dialog
                        :title="'添加全局配置'"
                        style="text-align:left !important"
                        :close-on-click-modal="false"
                        :visible.sync="addDialogVisible"
                >
                    <!-- 内容区域 -->
                    <el-form ref="addFormRef" :model="addform" :rules="addFormRules">
                        <el-row type="flex" class="row-bg" justify="space-around">
                            <el-col :span="24">
                                <el-form-item label="KEY" prop="key">
                                    <el-input v-model="addform.key"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" justify="space-around">
                            <el-col :span="24">
                                <el-form-item label="VALUE" prop="value">
                                    <el-input   type="textarea"
                                                :autosize="{ minRows: 3, maxRows: 6}"
                                                v-model="addform.value"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <!-- 按钮区域 -->
                    <span slot="footer" class="dialog-footer">
                        <el-button type="success" @click="addList()">提交</el-button>
                        <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
                    </span>
                </el-dialog>

                <!-- 编辑全局配置 -->
                <el-row >
                    <el-dialog title="编辑全局配置"
                               :visible.sync="editDialogVisible"
                               :close-on-click-modal="false">
                        <el-form ref="editform"  :model="editform" :rules="editFormRules">
                            <el-row type="flex" class="row-bg" justify="space-around">
                                <el-col :span="24">
                                    <el-form-item label="KEY" prop="key">
                                        <el-input v-model="editform.key" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="row-bg" justify="space-around">
                                <el-col :span="24">
                                    <el-form-item label="VALUE" prop="value">
                                        <el-input   type="textarea"
                                                    :autosize="{ minRows: 3, maxRows: 6}"
                                                    v-model="editform.value"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row type="flex" justify="end">
                                <el-button @click="editDialogVisible = false">取 消</el-button>
                                <el-button type="primary" @click="editok()">确 定</el-button>
                            </el-row>
                        </el-form>
                    </el-dialog>
                </el-row>
            </el-card>
        </div>
    </el-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                addDialogVisible: false,
                editDialogVisible: false,
                tableData: [],
                queryInfo: {
                    page: 1,
                    pagenum: 20
                },
                total: 0,
                addform: {
                    key:"",
                    value: "",
                },
                // 添加验证表单对象
                addFormRules: {
                    key:[{ required: true, message: "请输入", trigger: "blur" }],
                    value: [{ required: true, message: "请输入", trigger: "blur" }],
                },
                // 编辑表单
                editform: {
                    value: "",
                },
                // 添加验证表单对象
                editFormRules: {
                    value: [{ required: true, message: "请输入", trigger: "blur" }],
                },
            };
        },
        mounted() {
            this.gettable();
        },
        methods: {
            change(newPage) {
                this.loading = false;
                this.handleCurrentChange(newPage);
                this.loading = true;
            },
            // 监听pageName改变的事件
            handleSizeChange(pagenum) {
                this.loading = false;
                this.queryInfo.pagenum = pagenum;
                this.gettable();
                this.loading = true;
            },
            // 监听页码值改变的事件
            handleCurrentChange(newPage) {
                this.loading = false;
                this.queryInfo.page = newPage;
                this.gettable();
                this.loading = true;
            },
            gettable() {
                let that = this;
                var params = this.queryInfo;
                this.$Http({
                    url: "/api/variables/getGloble",
                    params,
                    successful(res, data) {
                        that.loading = false;
                        that.tableData = data.data.data;
                        that.total = data.data.total;
                    }
                });
            },
            // 编辑按钮
            edit(index, row) {
                this.editDialogVisible = true;
                this.editform = Object.assign({}, row);
                this.editform.key = row.key;
            },
            // 编辑提交按钮
            editok() {
                var params = this.editform;
                let that = this;
                this.$Http({
                    url: "/api/variables/updateGloble",
                    params,
                    successful(res, data) {
                        if (data.status == false) {
                            that.$message.error("编辑"+data.message);
                        }
                        if (data.status == true) {
                            that.$message.success("编辑"+data.message);
                            that.gettable();
                            that.editDialogVisible = false;
                        }
                    }
                });
            },
            // 新增弹框
            addlis() {
                this.addDialogVisible = true;
            },
            // 新增确定按钮
            // 点击添加按钮添加列表
            addList() {
                var params = this.addform;
                let that = this;
                this.$refs.addFormRef.validate(valid => {
                    if (!valid) return;
                    //  添加请求
                    this.$Http({
                        url: "/api/variables/addGloble",
                        params,
                        successful(res, data) {
                            if (data.status == false) {
                                that.$message.error(data.message);
                            } else {
                                if (data.code != 200) {
                                    that.$message.error(data.message);
                                    that.code = res.data.data.code;
                                } else {
                                    that.addDialogVisible = false;
                                    that.gettable();
                                    that.$message.success("添加成功");
                                }
                            }
                        }
                    });
                });
            },
            // 新增取消按钮
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.addDialogVisible = false;
            },
            reset(formName){
                this.$refs[formName].resetFields();
            },
            // 删除按钮
            del(index, row) {
                var params = { key: row.key };
                let that = this;
                // 根据弹框删除
                const confirmResult = this.$confirm(
                    "此操作将删除该列表，是否继续？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$Http({
                            url: "/api/variables/delGloble",
                            params,
                            successful(res, data) {
                                if (data.status == false) {
                                    that.$message.error("删除"+data.message);
                                } else {
                                    that.$message.success("删除"+data.message);
                                    that.gettable();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        that.$message.info("已取消删除");
                    });
            },

            // 维护按钮
            replace(index, row) {
                var params = { id: row.id };
                let that = this;
                // 根据弹框删除
                const confirmResult = this.$confirm(
                    "此操作将更新全局变量，是否继续？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$Http({
                            url: "/api/variables/replaceGloble",
                            params,
                            successful(res, data) {
                                if (data.status == false) {
                                    that.$message.error(data.message);
                                } else {
                                    that.$message.success(data.message);
                                    that.gettable();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        that.$message.info("已取消维护");
                    });
            }
        }
    };
</script>
<style lang="less" scoped>
    .page {
      margin-top: 20px;
    }
  .button {
    font-weight: 700;
    background: #6495ed;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    margin-top: initial;
    color: #f5f5f5;
  }

  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
</style>
