<template>
    <!-- 大厅列表 -->
    <el-card
            shadow="hover"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="height: 100%;overflow-y: auto;"
    >
        <div v-loading="loading" style="height: 100%;">
            <el-card>
                <el-row justify="end" type="flex">
                    <el-col>
                        <h3>大厅列表</h3>
                    </el-col>
                    <el-col :span="3" style="margin:15px 0">
                        <el-button type="primary" class="button" @click="addlis()" icon="el-icon-circle-plus-outline" round>新建大厅列表</el-button>
                    </el-col>
                </el-row>
                <!-- 表格列表 -->
                <el-row>
                    <el-table ref="singleTable" :data="tableData" border style="width: 100%" height="630px">
                        <el-table-column property="id" width="100" label="ID" align="center"></el-table-column>
                        <el-table-column property="addr" label="节点地址" align="center"></el-table-column>
                        <el-table-column label="是否开放" prop="is_open" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.is_open===0?'关闭':'开启'}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="状态" prop="status" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == 0" style="color: red">关闭</span>
                                <span v-if="scope.row.status == 1" style="color: #24af08">启用</span>
                                <span v-if="scope.row.status == 2" style="color: #09f1a4">使用中</span>
                                <span v-if="scope.row.status == 3" style="color: #d1c700">维护中</span>
                                <span v-if="scope.row.status == 4" style="color: #0b8bf1">维护完成</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" :disabled="scope.row.status == 1?false:true " type="info" @click="uphold(scope.$index, scope.row)">切换</el-button>
                                <el-button size="mini" type="warning" @click="edit(scope.$index, scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" @click="del(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <!-- 分页器 -->
                <el-row>
                    <div class="page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="queryInfo.page"
                                :page-size="queryInfo.pagenum"
                                :page-sizes="[20, 50, 100, 200,500]"
                                style="float:right"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="total"
                        ></el-pagination>
                        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
                    </div>
                </el-row>
                <!-- 添加大厅列表 -->
                <el-dialog
                        :title="'添加大厅列表'"
                        style="text-align:left !important"
                        :close-on-click-modal="false"
                        :visible.sync="addDialogVisible"
                >
                    <!-- 内容区域 -->
                    <el-form ref="addFormRef" :model="addform" width="40%" :rules="addFormRules">
                        <el-row type="flex" class="row-bg" justify="space-around">
                            <el-col :span="6">
                                <el-form-item label="ID" prop="id">
                                    <el-input v-model="addform.id" ></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="节点地址" prop="addr">
                                    <el-input v-model="addform.addr"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row type="flex" class="row-bg" justify="space-around">
                            <el-col :span="6">
                                <el-form-item label="是否开放" prop="is_open">
                                    <el-select v-model="addform.is_open" placeholder="是否开放">
                                        <el-option label="开" value="1"></el-option>
                                        <el-option label="关" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="服务器状态" prop="status">
                                    <el-select v-model="addform.status" placeholder="状态">
                                        <el-option label="空闲" value="1"></el-option>
                                        <el-option label="关闭" value="0"></el-option>
                                        <el-option label="维护" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </el-form>
                    <!-- 按钮区域 -->
                    <span slot="footer" class="dialog-footer">
                        <el-button type="success" @click="addList()">提交</el-button>
                        <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
                    </span>
                </el-dialog>

                <!-- 编辑类目 -->
                <el-row >
                    <el-dialog title="编辑大厅列表"
                               :visible.sync="editDialogVisible"
                               width="40%"
                               :close-on-click-modal="false">
                        <el-form ref="editform"  :model="editform" :rules="editFormRules">
                            <el-row type="flex" class="row-bg" justify="space-around">
                                <el-col :span="6">
                                    <el-form-item label="节点地址" prop="addr">
                                        <el-input v-model="editform.addr"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="6">
                                    <el-form-item label="是否开放" prop="is_open">
                                        <el-select v-model="editform.is_open" placeholder="是否开放">
                                            <el-option label="开" :value="1"></el-option>
                                            <el-option label="关" :value="0"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row type="flex" justify="end">
                                <el-button @click="editDialogVisible = false">取 消</el-button>
                                <el-button type="primary" @click="editok()">确 定</el-button>
                            </el-row>
                        </el-form>
                    </el-dialog>
                </el-row>
            </el-card>
        </div>
    </el-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                addDialogVisible: false,
                editDialogVisible: false,
                tableData: [],
                queryInfo: {
                    page: 1,
                    pagenum: 20
                },
                total: 0,
                addform: {
                    id:"",
                    is_open: "",
                    status: "",
                    addr:"",
                },
                // 添加验证表单对象
                addFormRules: {
                    id:[{ required: true, message: "请输入", trigger: "blur" }],
                    is_open: [{ required: true, message: "请选择是否开放", trigger: "blur" }],
                    status: [{ required: true, message: "请选择服务器状态", trigger: "blur" }],
                    addr: [{ required: true, message: "请输入节点地址", trigger: "blur" }],
                },
                // 编辑表单
                editform: {
                    id:"",
                    is_open: "",
                    status: "",
                    addr:"",
                },
                // 添加验证表单对象
                editFormRules: {
                    is_open: [{ required: true, message: "请选择是否开放", trigger: "blur" }],
                    status: [{ required: true, message: "请选择服务器状态", trigger: "blur" }],
                    addr: [{ required: true, message: "请输入节点地址", trigger: "blur" }],
                },
            };
        },
        mounted() {
            this.gettable();
        },
        methods: {
            change(newPage) {
                this.loading = false;
                this.handleCurrentChange(newPage);
                this.loading = true;
            },
            // 监听pageName改变的事件
            handleSizeChange(pagenum) {
                this.loading = false;
                this.queryInfo.pagenum = pagenum;
                this.gettable();
                this.loading = true;
            },
            // 监听页码值改变的事件
            handleCurrentChange(newPage) {
                this.loading = false;
                this.queryInfo.page = newPage;
                this.gettable();
                this.loading = true;
            },
            gettable() {
                let that = this;
                var params = this.queryInfo;
                this.$Http({
                    url: "/api/platfrom/config/getLobbyList",
                    params,
                    successful(res, data) {
                        that.loading = false;
                        that.tableData = data.data.data;
                        that.total = data.data.total;
                    }
                });
            },
            // 编辑按钮
            edit(index, row) {
                this.editDialogVisible = true;
                this.editform = Object.assign({}, row);
                this.editform.id = row.id;
            },
            // 编辑提交按钮
            editok() {
                var params = this.editform;
                let that = this;
                this.$Http({
                    url: "/api/platfrom/config/upLobby",
                    params,
                    successful(res, data) {
                        if (data.status == false) {
                            that.$message.error("编辑"+data.message);
                        }
                        if (data.status == true) {
                            that.$message.success("编辑"+data.message);
                            that.gettable();
                            that.editDialogVisible = false;
                        }
                    }
                });
            },
            // 新增弹框
            addlis() {
                this.addDialogVisible = true;
            },
            // 新增确定按钮
            // 点击添加按钮添加列表
            addList() {
                var params = this.addform;
                let that = this;
                this.$refs.addFormRef.validate(valid => {
                    if (!valid) return;
                    //  添加请求
                    this.$Http({
                        url: "/api/platfrom/config/addLobby",
                        params,
                        successful(res, data) {
                            if (data.status == false) {
                                that.$message.error("添加"+data.message);
                            } else {
                                if (data.code != 200) {
                                    that.$message.error(data.message);
                                    that.code = res.data.data.code;
                                } else {
                                    that.addDialogVisible = false;
                                    that.gettable();
                                    that.$message.success("添加成功");
                                }
                            }
                        }
                    });
                });
            },
            // 新增取消按钮
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.addDialogVisible = false;
            },
            reset(formName){
                this.$refs[formName].resetFields();
            },
            // 删除按钮
            del(index, row) {
                var params = { id: row.id };
                let that = this;
                // 根据弹框删除
                const confirmResult = this.$confirm(
                    "此操作将删除该列表，是否继续？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$Http({
                            url: "/api/platfrom/config/delLobby",
                            params,
                            successful(res, data) {
                                if (data.status == false) {
                                    that.$message.error("删除"+data.message);
                                } else {
                                    that.$message.success("删除"+data.message);
                                    that.gettable();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        that.$message.info("已取消删除");
                    });
            },

            // 维护按钮
            uphold(index, row) {
                var params = { id: row.id};
                let that = this;
                // 根据弹框删除
                const confirmResult = this.$confirm(
                    "此操作将切换大厅，此过程需要大约30秒，是否继续？",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$Http({
                            url: "/api/platfrom/config/upholdLobby",
                            params,
                            successful(res, data) {
                                if (data.status == false) {
                                    that.$message.error(data.message);
                                } else {
                                    if(data.data.d.code == 0){
                                        that.$message.success(data.data.d.msg);
                                        that.timeOut();
                                    }else{
                                        that.$message.success(data.data.d.msg);
                                    }
                                    that.gettable();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        that.$message.info("已取消维护");
                    });
            },
            timeOut(){
                setTimeout(() => {
                    this.gettable();
                }, 35000)
            }

        }
    };
</script>
<style lang="less" scoped>
    .page {
      margin-top: 20px;
    }
  .button {
    font-weight: 700;
    background: #6495ed;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    margin-top: initial;
    color: #f5f5f5;
  }

  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
</style>
