<template>
    <!-- 系统设置赠送管理 -->
    <el-card
            shadow="hover"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            style="height: 100%;overflow-y: auto;"
    >
        <div v-loading="loading" style="height: 100%;">
            <el-card>
                <h4 style="margin-bottom:20px">赠送设置</h4>
                <el-row>
                    <el-col>赠送最小金额：{{min_give_gold}}</el-col>
                    <el-col style="margin-top:20px">赠送手续费(百分比)：{{give_tax}}%</el-col>
                </el-row>
                <el-row type="flex" justify="center">
<!--                    <el-button type="primary" @click="look(notice_url)">查看</el-button>-->
                    <el-button type="primary" @click="update()">更新</el-button>
                </el-row>
                <!-- 更新 -->
                <el-dialog title="更新" :visible.sync="dialogVisible1" width="60%" @close="reset()">
                    <el-row type="flex" :gutter="20">
                        <el-col :span="12">
                            <el-form ref="form" :model="form" label-width="120px">
                                <el-form-item label="赠送最小金额:" prop="min_give_gold">
                                    <el-input v-model.number="form.min_give_gold" clearable></el-input>
                                </el-form-item>

                                <el-form-item label="赠送手续费:" prop="give_tax">
                                    <el-input placeholder="请输入内容" v-model="form.give_tax">
                                        <template slot="append">%</template>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <span slot="footer" class="dialog-footer">
                          <el-button @click="no ()">取 消</el-button>
                          <el-button type="primary" @click="yess()">提交</el-button>
                    </span>
                </el-dialog>
            </el-card>
        </div>
    </el-card>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                min_give_gold: "",
                give_tax: "",
                form: { min_give_gold: "", give_tax: "" },
                // 更新弹出
                dialogVisible1: false
            };
        },
        mounted() {
            this.getGive();
        },
        methods: {
            getGive() {
                let that = this;
                this.$Http({
                    url: "/api/platfrom/config/getGive",
                    successful(res, data) {
                        that.loading = false;
                        that.min_give_gold = data.data[1].value;
                        that.give_tax = data.data[0].value;
                    }
                });
            },
            update() {
                this.dialogVisible1 = true;
                this.form.min_give_gold = this.min_give_gold;
                this.form.give_tax = this.give_tax;
            },
            // 更新弹框取消按钮
            no() {
                this.dialogVisible1 = false;
                this.form.min_give_gold = "";
            },
            // 更新版本提交
            yess() {
                var params = this.form;
                if (this.form.min_give_gold == "") {
                    this.$message.error("赠送最小金额不能为空");
                } else if (this.form.give_tax == "") {
                    this.$message.error("赠送手续费(百分比)不能为空");
                } else if(this.form.give_tax < 0 || this.form.give_tax > 100){
                    this.$message.error("赠送手续费(百分比)值必须在0-100");
                }else {
                    let that = this;
                    this.$Http({
                        url: "/api/platfrom/config/updateGive",
                        params,
                        successful(res, data) {
                            that.$message.success("更新成功");
                            that.getGive();
                            that.form.min_give_gold = "";
                            that.dialogVisible1 = false;
                        }
                    });
                }
            },
            reset() {
                this.form.notice_url = "";
            }
        }
    };
</script>

<style lang="less" scoped>
    #anlysis {
        /deep/.el-input__inner {
            text-align: center !important;
        }
        .statistics {
            color: red;
            font-weight: 600;
            margin-bottom: 20px;
            .fontstyle {
                margin: 0 20px;
            }
        }
        .gamename {
            color: #409eff;
            cursor: pointer;
        }
        .page {
            margin-top: 20px;
        }
    }

    .el-button+.el-button {
        margin-left: 10px;
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
    }

</style>