<template>
  <!-- 系统设置公告管理 -->
  <el-card
      shadow="hover"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="height: 100%;overflow-y: auto;"
  >
  <div v-loading="loading" style="height: 100%;">
    <el-card>
      <h4 style="margin-bottom:20px">公告管理</h4>
      <el-row>
        <el-col>公告URL：{{notice_url}}</el-col>
        <el-col style="margin-top:20px">公告版本：{{notice_version}}</el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="look(notice_url)">查看</el-button>
        <el-button @click="update()">更新</el-button>
      </el-row>
      <!-- 更新 -->
      <el-dialog title="更新" :visible.sync="dialogVisible1" width="60%" @close="reset()">
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <el-form ref="form" :model="form" label-width="120px">
              <el-form-item label="公告URL:" prop="notice_url">
                <el-input v-model="form.notice_url" clearable placeholder="https://......"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col>
            <el-button @click="lok(form.notice_url)" type="primary">查看</el-button>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <el-form ref="form" :model="form" label-width="120px">
              <el-form-item label="公告版本:" prop="notice_version">{{this.form.notice_version}}</el-form-item>
            </el-form>
          </el-col>
          <el-col :span="3">
            <el-button @click="up()" type="primary">更新版本号</el-button>
          </el-col>
          <el-col :span="10" style="color:#909399;line-height: 40px;">更新公告的新版本号，客户端将忽略"本次登录不在弹出"的规则</el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="no ()">取 消</el-button>
          <el-button type="primary" @click="yess()">提交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
  </el-card>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        notice_url: "",
        notice_version: "",
        form: { notice_url: "", notice_version: "" },
        // 更新弹出
        dialogVisible1: false
      };
    },
    mounted() {
      this.geturl();
      this.up();
    },
    methods: {
      geturl() {
        let that = this;
        this.$Http({
          url: "/api/platfrom/config/getConfig",
          successful(res, data) {
            that.loading = false;
            that.notice_url = data.data[0].value;
            that.notice_version = data.data[1].value;
          }
        });
      },
      look(url) {
        window.open(url, "_blank"); // 新窗口打开外链接
      },
      update() {
        this.dialogVisible1 = true;
        this.up();
      },
      // 更新弹框取消按钮
      no() {
        this.dialogVisible1 = false;
        this.form.notice_url = "";
      },
      // 更新弹框提交按钮
      yes() {
        this.dialogVisible1 = false;
      },
      lok(url) {
        window.open(url, "_blank"); // 新窗口打开外链接
      },
      // 更新页面更新版本号
      up() {
        var now = new Date();
        var year = now.getFullYear(); //得到当前年份
        var month = now.getMonth() + 1; //默认得到月份是上一个月，如果当前是3月，这个值为2月
        var day = now.getDate();
        var h = now.getHours();
        var m = now.getMinutes();
        var s = now.getSeconds();
        month = month.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
        day = day.toString().padStart(2, "0"); //当小于10时，显示为01.02.03
        h = h.toString().padStart(2, "0");
        m = m.toString().padStart(2, "0");
        s = s.toString().padStart(2, "0");
        let news = year + "_" + month + day + "_" + h + m + "_" + s + "00";
        this.form.notice_version = news;
      },
      // 更新版本提交
      yess() {
        var params = this.form;
        if (this.form.notice_url == "") {
          this.$message.error("公告URL不能为空");
        } else if (this.form.notice_version == this.notice_version) {
          this.$message.error("公告版本没有更新");
        } else {
          let that = this;
          this.$Http({
            url: "/api/platfrom/config/update",
            params,
            successful(res, data) {
              that.$message.success("更新成功");
              that.geturl();
              that.form.notice_url = "";
              that.dialogVisible1 = false;
            }
          });
        }
      },
      reset() {
        this.form.notice_url = "";
      }
    }
  };
</script>

<style lang="less" scoped>
  #anlysis {
    /deep/.el-input__inner {
      text-align: center !important;
    }
    .statistics {
      color: red;
      font-weight: 600;
      margin-bottom: 20px;
      .fontstyle {
        margin: 0 20px;
      }
    }
    .gamename {
      color: #409eff;
      cursor: pointer;
    }
    .page {
      margin-top: 20px;
    }
  }

  .el-button+.el-button {
    margin-left: 10px;
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
  }

</style>